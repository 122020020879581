import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  public environment = 'develop';
  public namespace = 'develop';
  public showAuthReferenceText = false;
  public enableGermanLanguageAuth = false;
  public oauth = '';
  public auth = '';
  public backend = '';
  public wearable = '';
  public calendaring = '';
  public configBackend = '';
  public proprietary = '';
  public middlewareBackend = '';
  public cmsBackend = 'https://master-cms.medvision360.org';
  public cmsCategory = 'medsafe';
  public clientId = '2d6c9591-ec11-4ed6-9ba2-18c1b3087';
  public shortClientName = 'Medsafe';
  public defaultLanguage = 'nl';
  public signupAllowedRoles: string;
  public showWebsite = false;
  public enableTestHealthcareProvider = false;
  public googleAuthDisabled = false;
  public showPayments = false;
  public disableRemember = false;
  public disableRegister = false;
  public whitelistMedmijLoginOrigin: 'https://medsafe.medvision360.org/';
  public weatherApi = 'https://api.weatherapi.com/v1/';

  // jan-marc@medrecord.io account registered
  public weatherApiKey = '11bd319ae05b4f30ac8125651231010';

  public chatbotApi = '';
  public healthTalkGui = '';
  public healthTalkConsultationManager = 'https://consultation-manager.develop.develop.medrecord-innovations.online';
  public healthTalkConsultation = 'https://healthtalk-backend.develop.develop.medrecord-innovations.online';
  public tenantId = '';
  public apiGateway = '';
  public aiModelProxy = '';
}
